import { Card, Col, Container, Row } from "react-bootstrap"
import { CardBody } from "reactstrap"


export default function NotificationBody({ children }) {
    return (
        <Container className="mt-4">
            <Row className="justify-content-md-center">
                <Col xs={12}>
                    <Card>
                        <CardBody className='p-0 p-md-4'>
                            {children}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}