import { useContext, useEffect, useState } from 'react';
import { PaymentContext } from "./context";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';

import { ChangeCountry } from './services/api';

// local storage
import { removeStorageValue } from "./services/localstorage";

import { get_message } from '../../lang/messages';
import { get_error_message } from '../../lang/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// cookies
import { useCookies } from 'react-cookie';
import { Button, Col, Row } from 'react-bootstrap';

import { countries } from '../../data/countries';


const BackButton = () => {
    const { isWorking } = useContext(PaymentContext).mworking;
    const { lang } = useContext(PaymentContext).mlang;
    const { session } = useContext(PaymentContext).msession;

    const onCancelSession = (event) => {
        if (session !== undefined && session !== null)
            removeStorageValue(session.sid);
    }

    const handleReturn = (url) => {
        onCancelSession()
        window.location.href = url
    }

    return (
        <Button
            disabled={isWorking}
            variant="link"
            type="button"
            className='btn-back'
            style={{
                fontSize: '13px'
            }}
            onClick={() => handleReturn(session.payment.pg_cancel_url)}
        >
            {get_message('BACK_TO_SITE', lang)}
        </Button>
    )
}

const PaymentWindowFooter = () => {
    // global context
    const { session, setSession } = useContext(PaymentContext).msession;
    const setMethod = useContext(PaymentContext).mmethod.setMethod;
    const setError = useContext(PaymentContext).merror.setError;
    const { isLoaded } = useContext(PaymentContext).mloaded;
    const { isWorking, setIsWorking } = useContext(PaymentContext).mworking;
    const { lang, setLang } = useContext(PaymentContext).mlang;

    const displayCountries = true;

    const LANG_LIST = ['EN', 'ES', 'PT'];

    const [cookies] = useCookies(['pgcheckouttoken'])

    const [countryList, setCountries] = useState([])

    // const onCancelSession = (event) => {
    //     if (session !== undefined && session !== null)
    //         removeStorageValue(session.sid);
    // };

    const onChangeLanguaje = (event) => {
        setLang(event.toUpperCase())
    };

    const resetData = (temp) => {
        temp.payment.pg_method = null;
        let keys = Object.keys(temp.payment);

        for (let i = 0; i < keys.length; ++i) {
            if ('' === temp.payment[keys[i]])
                delete temp.payment[keys[i]];
        }

        return temp;
    };

    const onChangeCountry = (event) => {
        let country = event;
        let temp = JSON.parse(JSON.stringify(session));

        // cambiar pais        
        temp.payment.pg_country = country;
        temp = resetData(temp);
        setIsWorking(true);
        setError("");

        ChangeCountry(temp.payment, cookies.pgcheckouttoken)
            .then((result) => {

                // expired session token
                if (result.hasOwnProperty('code') && result.code === 53) {
                    temp.code = result.code
                    temp.code_message = result.code_message;
                    temp.errors = result.errors;
                    setSession(temp);
                    if (result.message !== "")
                        setError("(" + result.code + ") " + get_error_message(result.code, lang) + ": " + result.message);
                    else
                        setError("(" + result.code + ") " + get_error_message(result.code, lang));

                    setIsWorking(false);
                    return;
                }

                // handle payments errors
                if (result.hasOwnProperty('error')) {
                    setError(result.details);
                    setIsWorking(false);
                    return;
                }

                // handle fastapi validator errors
                if (result.hasOwnProperty('details')) {
                    setError(result.details[0].msg);
                    setIsWorking(false);
                    return;
                }

                // actualizar listado de metodos
                temp.methods = result;
                // setear step 1 (listado de metodos)
                temp.step = 1;
                temp.payment.pg_method = null;
                temp.type = '';
                temp.custom_args = [];
                // actualizar session context
                setSession(temp);
                setMethod(null);
                setIsWorking(false);
            },
                (error) => {
                    setError(error);
                    setIsWorking(false);
                });
    }

    useEffect(() => {
        const temp = (countries[lang] ?? countries['EN']).sort((a, b) => a.name > b.name ? 1 : -1)
        setCountries(temp)
    }, [lang])

    // el componente "padre" no termina de cargar
    if (!isLoaded || session === undefined)
        return (
            <div className="card-footer">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{get_message('LOADING', 'EN')}...</span>
                </Spinner>
            </div>
        );

    // componente "padre" cargado, pero con error (el setError() lo llama el "padre")
    if (session === null)
        return (
            <div className="card-footer"></div>
        );

    // expired csrf token
    if (session.code === 53)
        return (
            <div className="card-footer">
                {!isWorking && (<Row>
                    <Col xs={12} sm={6}>
                        <div className="d-grid gap-2 my-3">
                            {<BackButton />}
                        </div>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Dropdown onSelect={onChangeLanguaje} className='dropdown-language mt-3'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={isWorking}>
                                <FontAwesomeIcon icon="language" /> {lang.toUpperCase()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {LANG_LIST.filter(c => c.toUpperCase() !== lang.toUpperCase()).sort((a, b) => a.localeCompare(b)).map((l, idx) => (
                                    <Dropdown.Item key={idx} eventKey={l}>
                                        {l.toUpperCase()}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>)}
            </div>
        );

    // API no puede cambiar pais
    if (session.src === "0" || session.src === 0)
        return (
            <div className="card-footer">
                {!isWorking && (<Row>
                    <Col xs={12} sm={6}>
                        <div className="d-grid gap-2 my-3">
                            {<BackButton />}
                        </div>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Dropdown onSelect={onChangeLanguaje} className='dropdown-language mt-3'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={isWorking}>
                                <FontAwesomeIcon icon="language" /> {lang.toUpperCase()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {LANG_LIST.filter(c => c.toUpperCase() !== lang.toUpperCase()).sort((a, b) => a.localeCompare(b)).map((l, idx) => (
                                    <Dropdown.Item key={idx} eventKey={l}>
                                        {l.toUpperCase()}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>)}
            </div>
        );

    return (
        <div className="card-footer">
            {!isWorking && (<Row>
                <Col xs={6} className="text-start ps-4">
                    <Dropdown onSelect={onChangeLanguaje} className='dropdown-language mt-3'>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '90px' }} disabled={isWorking}>
                            <FontAwesomeIcon icon="language" /> {lang.toUpperCase()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {LANG_LIST.filter(c => c.toUpperCase() !== lang.toUpperCase()).sort((a, b) => a.localeCompare(b)).map((l, idx) => (
                                <Dropdown.Item key={idx} eventKey={l}>
                                    {l.toUpperCase()}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                {displayCountries && (<Col xs={6} className="text-end pe-4">
                    <Dropdown onSelect={onChangeCountry} className="dropdown-language mt-3">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '90px' }} disabled={isWorking}>
                            <img src={`https://cdn.paygol.com/images/flags/${session.payment.pg_country.toUpperCase()}.png`} alt={session.payment.pg_country} style={{ width: "30px" }} /> {session.payment.pg_country.toUpperCase()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{
                            maxHeight: '200px',
                            overflowY: 'scroll'
                        }}>

                            {countryList.filter(c => c.code !== session.payment.pg_country).map((country, idx) => (
                                <Dropdown.Item key={idx} eventKey={country.code}>
                                    <img src={`https://cdn.paygol.com/images/flags/${country.code.toUpperCase()}.png`} alt={country.name} style={{ width: "30px" }} /> {country.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>)}

                <Col xs={12}>
                    <div className="d-grid gap-2 my-3">
                        {<BackButton />}
                    </div>
                </Col>
            </Row>)}

            <Row>
                <Col>
                    <Button
                        href="https://paygol.com/politica-de-privacidad/"
                        variant="link"
                        target='_blank'
                        rel='noopener'
                        className='btn-back'
                        style={{
                            fontSize: '13px'
                        }}
                    >{get_message('PRIVACY_POLICY', lang)}</Button>
                </Col>
                <Col>
                    <Button
                        href="https://paygol.com/politica-de-cookies/"
                        variant="link"
                        target='_blank'
                        rel='noopener'
                        className='btn-back'
                        style={{
                            fontSize: '13px'
                        }}
                    >{get_message('COOKIES_POLICY', lang)}</Button>
                </Col>
            </Row>
        </div>
    );
};

export { PaymentWindowFooter };
