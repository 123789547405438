import { Container, Spinner } from 'react-bootstrap'

export default function BackdropWrapper() {
    return (
        <Container fluid className="text-center pg-blue1" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            color: '#FFF'
        }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Container>
    )
}


