import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { BASE_URL, PAYMENT_WINDOW_URL } from '../../../config'
import Ticket from '../Ticket'

export default function EmailInvoice() {
    const { token } = useParams()

    const [loading, setLoading] = useState(true)

    const [invoice, setInvoice] = useState()

    const [items, setItems] = useState([])

    const [logo, setLogo] = useState()

    const [inputs, setInputs] = useState({
        pg_invoice_uid: token,
        pg_serviceid: '',
        pg_currency: '',
        pg_name: '',
        pg_custom: '',
        pg_price: '',
        pg_language: '',
        pg_return_url: '',
        pg_cancel_url: '',
    })

    const getDocumentInfo = useCallback(() => {
        fetch(`${BASE_URL}/webcheckout/invoices/${token}`, { method: 'GET' })
            .then((response) => response.json())
            .then(({ invoice, items, contacts }) => {
                const regex = new RegExp(/(http|https)/)

                invoice.customer_service = contacts ? contacts.filter(c => /cliente/i.test(c.title))[0] : ''

                const custom_log = invoice.logo_file ? (regex.test(String(invoice.logo_file)) ? invoice.logo_file : `https://cdn.paygol.com/merchant/logos/${invoice.logo_file}`) : ''

                setLogo(custom_log)

                const return_url = `${PAYMENT_WINDOW_URL}/email-invoice/${token}?ok`
                const cancel_url = `${PAYMENT_WINDOW_URL}/email-invoice/${token}?nok`

                setInputs({
                    ...inputs,
                    pg_invoice_id: invoice.id,
                    pg_serviceid: invoice.service_id,
                    pg_currency: invoice.currency,
                    pg_language: invoice.language ? invoice.language : 'ES',
                    pg_name: invoice.title,
                    pg_custom: invoice.details,
                    pg_price: invoice.amount,
                    pg_return_url: return_url,
                    pg_cancel_url: cancel_url
                })

                setInvoice(invoice)

                setItems(items)

            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }, [inputs, token])

    useEffect(() => {
        getDocumentInfo()

        document.body.classList.remove('pg-blue1')
        document.body.classList.add('pg-white')

        // eslint-disable-next-line
    }, [token])

    return (
        <Ticket
            invoice={invoice}
            inputs={inputs}
            items={items}
            logo={logo}
            loading={loading}
            is_payment_link={false}
        />
    )
}
