// react
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';

// bootstrap
import Spinner from 'react-bootstrap/Spinner';

import { get_error_message } from '../../lang/index';

// api connector
import { LoadSession } from '../paymentwindow/services/api';

// cookies
import { useCookies } from 'react-cookie';
import Body from "../Body";
import { get_message } from "../../lang/messages";
import { BiErrorAlt } from "react-icons/bi";


// "main" base component
const ErrorWindow = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('');
    const [query] = useState(new URLSearchParams(useLocation().search));
    const [errors, setErrors] = useState([]);

    const [lang] = useState('ES');
    const [cookies] = useCookies(['pgcheckouttoken']);

    useEffect(() => {
        const code = query.get('code');

        if (null === code) {
            setError('Missing Error Code');
        }
        else {
            setError(get_error_message(code, lang));
        }

        // ver si viene la sid
        const sid = query.get('sid');

        if (sid === null) {
            setIsLoaded(true);
            return;
        }

        LoadSession(sid, cookies.pgcheckouttoken).then((result) => {
            if ('errors' in result && Array.isArray(result.errors)) {
                setErrors(result.errors);
            }

            if ('code' in result && result['code'] !== null) {
                setError('(' + code + ') ' + get_error_message(code, lang));
            }

            setIsLoaded(true);
        },
            (error) => {
                setError(error);
                setIsLoaded(true);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [query]);

    // cargando...
    if (!isLoaded)
        return (
            <Body>
                <div className="card-header"></div>
                <div className="card-body">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Body>
        );

    if (!Array.isArray(errors) || errors.length === 0)
        return (
            <Body>
                <div className="card-header">&nbsp;</div>
                <div className="card-body">
                    <h1 style={{
                        color: '#F50D54',
                        fontSize: '44px'
                    }}><BiErrorAlt /></h1>

                    <h5 style={{
                        color: '#F50D54',
                        fontWeight: '600'
                    }} className="mt-4">{error}</h5>

                    <h6 style={{
                        fontWeight: 'lighter'
                    }} className="mt-3">{get_message('PLEASE_TRY_AGAIN', lang)}</h6>
                </div>
            </Body>
        );

    return (
        <Body>
            <div className="card-header">&nbsp;</div>
            <div className="card-body">
                <h1 style={{
                    color: '#F50D54',
                    fontSize: '44px'
                }}><BiErrorAlt /></h1>

                <h5 style={{
                    color: '#F50D54',
                    fontWeight: '600'
                }} className="mt-4">{error}</h5>

                <h6 style={{
                    fontWeight: 'lighter'
                }} className="mt-3">{get_message('PLEASE_TRY_AGAIN', lang)}</h6>
            </div>
        </Body>
    );
}

export { ErrorWindow };
