import { useContext, useState } from 'react';
import { PaymentContext } from "./context";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';

import { ValidatePayment } from './services/api';

import { get_message } from '../../lang/messages';
import { get_error_message } from '../../lang/index';

// local storage
import { removeStorageValue } from "./services/localstorage";

// cookies
import { useCookies } from 'react-cookie';
import { Col, Row } from 'react-bootstrap';
import NotificationBody from './NotificationBody';
import ErrorMessage from '../common/ErrorMessage';
import InfoMessage from '../common/InfoMessage';

const PaymentWindowMethods = () => {
    // global context
    const { session, setSession } = useContext(PaymentContext).msession;
    const setMethod = useContext(PaymentContext).mmethod.setMethod;
    const setError = useContext(PaymentContext).merror.setError;
    const { isLoaded } = useContext(PaymentContext).mloaded;
    const { isWorking, setIsWorking } = useContext(PaymentContext).mworking;
    const { errors, setErrors } = useContext(PaymentContext).merrors;
    const { lang } = useContext(PaymentContext).mlang;
    const { custom, setCustom } = useContext(PaymentContext).mcustom;


    const [cookies] = useCookies(['pgcheckouttoken']);

    const [redirecting, setRedirecting] = useState(false);

    const handle_pick_type = (type) => {
        let temp = JSON.parse(JSON.stringify(session));
        temp.step = 2;
        temp.type = type;
        temp.payment.pg_method = null;
        temp.custom_args = null;
        setSession(temp);
    };

    const handle_pick_method = (method) => {
        let temp = JSON.parse(JSON.stringify(session));
        temp.custom_args = null;

        // find custom_args in method list
        for (let i = 0; i < temp.methods.length; ++i) {
            if (temp.methods[i].method === method) {
                temp.custom_args = temp.methods[i].custom_args;

                // setear metodo seleccionado
                setMethod(temp.methods[i]);
                break;
            }
        }

        if (temp.custom_args === null) {
            setError(get_message('SELECTED_METHOD_UNAVAILABLE', lang));
        }
        else {
            // este metodo no tiene custom args
            // validate y redirect
            if (temp.custom_args.length === 0) {
                temp.payment.pg_method = method;
                validate(temp.payment);
                return;
            }

            // change step and set method
            if (session.mode === 1)
                temp.step = 2;
            else
                temp.step = 3;

            temp.payment.pg_method = method;
            let temp2 = {};

            // fill custom args
            for (let i = 0; i < temp.custom_args.length; ++i) {
                // setear '' si no viene 
                if (!temp.payment.hasOwnProperty(temp.custom_args[i].name)
                    || null === temp.payment[temp.custom_args[i].name]
                ) {
                    //temp.payment[temp.custom_args[i].name] = '';
                    if (custom !== null && custom.hasOwnProperty(temp.custom_args[i].name))
                        temp2[temp.custom_args[i].name] = custom[temp.custom_args[i].name];
                    else
                        temp2[temp.custom_args[i].name] = '';
                }
                else {
                    temp2[temp.custom_args[i].name] = temp.payment[temp.custom_args[i].name];
                }
            }

            // set updated session context
            setSession(temp);
            setCustom(temp2);
        }
    }

    const onArgChange = (event) => {
        const { id, value } = event.target;
        //let temp = JSON.parse(JSON.stringify(session));
        //temp.payment[id] = value;
        //setSession(temp);
        let temp2 = JSON.parse(JSON.stringify(custom || {}));
        temp2[id] = value;
        setCustom(temp2);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        setError('');

        if (form.checkValidity() === false) {
            setError(get_message('MISSING_FIELDS', lang));
            return;
        }

        //validate(session.payment);
        validate({ ...session.payment, ...custom });
    };

    const handleBack = () => {
        const payment = { ...session.payment, pg_method: null };

        setSession({
            ...session,
            code: null,
            step: 1,
            payment: payment
        })
    }

    const cleanParameters = (obj) => {
        if (obj && typeof obj === "object") {
            Object.keys(obj).map(key => {
                if (typeof obj[key] === "object") {
                    cleanParameters(obj[key])
                } else if (typeof obj[key] === "string") {
                    obj[key] = obj[key].trim()
                    obj[key] = obj[key].normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                }

                return obj
            })
        }
        return obj
    }

    // const getTrimmedData = (obj) => {
    //     if (obj && typeof obj === "object") {
    //         Object.keys(obj).map(key => {
    //             if (typeof obj[key] === "object") {
    //                 getTrimmedData(obj[key]);
    //             } else if (typeof obj[key] === "string") {
    //                 obj[key] = obj[key].trim();
    //             }

    //             return obj
    //         })
    //     }
    //     return obj
    // }

    // const removeTildes = (obj) => {
    //     if (obj && typeof obj === "object") {
    //         Object.keys(obj).map(key => {
    //             if (typeof obj[key] === "object") {
    //                 removeTildes(obj[key]);
    //             } else if (typeof obj[key] === "string") {
    //                 obj[key] = obj[key].normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    //             }

    //             return obj
    //         })
    //     }
    //     return obj
    // }

    const validate = (payment) => {
        setError("")
        setIsWorking(true)

        ValidatePayment(cleanParameters(payment), session.src, cookies.pgcheckouttoken)
            .then((result) => {
                // handle payments errors
                if (result.hasOwnProperty('code')) {
                    let temp = JSON.parse(JSON.stringify(session));
                    temp.code = result.code;
                    temp.error_message = result.message;
                    temp.errors = result.errors;

                    if (result.message !== "")
                        setError("(" + result.code + ") " + get_error_message(result.code, lang) + ": " + result.message);
                    else
                        setError("(" + result.code + ") " + get_error_message(result.code, lang));

                    if (result.hasOwnProperty('errors')) {
                        var errobj = {};
                        for (var i = 0; i < result.errors.length; ++i) {
                            let error_msg = ''

                            if (result.errors[i]['loc'][0] === 'pg_email') {
                                error_msg = get_error_message(62, lang)
                            } else if (result.errors[i]['loc'][0] === 'pg_personalid' && payment.pg_country === 'CL') {
                                error_msg = get_error_message(63, lang)
                            } else if (result.errors[i]['loc'][0] === 'pg_personalid' && payment.pg_country === 'BR') {
                                error_msg = get_error_message(64, lang)
                            }

                            errobj[result.errors[i]['loc'][0]] = error_msg
                        }

                        setErrors(errobj);
                    }

                    setSession(temp);
                    setIsWorking(false);
                    return;
                }

                setErrors({});

                // handle fastapi validator errors
                if (result.hasOwnProperty('details')) {
                    setError(result.details[0].msg);
                    setIsWorking(false);
                    return;
                }

                // no errors

                if (!result.hasOwnProperty('redirect_url')) {
                    setError(get_message('INVALID_REDIRECT_URL', lang));
                    setIsWorking(false);
                    return;
                }

                // remove localstorage
                removeStorageValue(session.sid);

                setRedirecting(true);
                setIsWorking(true);

                // redirect to paydirect
                window.location.href = result.redirect_url;
            },
                (error) => {
                    setIsWorking(false);
                    setError(error);
                });
    };


    // el componente "padre" no termina de cargar
    if (!isLoaded || session === undefined) {
        return (
            <div className="card-body">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{get_message('LOADING', lang)}...</span>
                </Spinner>
            </div>
        );
    }

    // componente "padre" cargado, pero con error (el setError() lo llama el "padre")
    if (session === null)
        return (
            <div className="card-body">
            </div>
        );

    // expired csrf token
    if (session.code === 53) {
        return (
            <NotificationBody>
                <ErrorMessage
                    heading={get_message('SOMETHING_WENT_WRONG', lang)}
                    message={get_message('NO_PAYMENT_METHODS_AVAILABLE', lang)}
                />
            </NotificationBody>
        );
    }


    // este o alguno de los "sub" componentes se encuentra trabajando (fetch/axios)
    if (isWorking) {
        if (redirecting)
            return (
                <NotificationBody>
                    <InfoMessage
                        message={get_message('REDIRECTING_TO_METHOD', lang)}
                    />
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">{get_message('LOADING', lang)}...</span>
                    </Spinner>
                </NotificationBody>
            );

        return (
            <div className="card-body">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{get_message('LOADING', lang)}...</span>
                </Spinner>
            </div>
        );
    }

    if (redirecting)
        return (
            <NotificationBody>
                <InfoMessage
                    message={get_message('REDIRECTING_TO_METHOD', lang)}
                />
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{get_message('LOADING', lang)}...</span>
                </Spinner>
            </NotificationBody>
        );


    // tipos de pago
    if (!session.hasOwnProperty('step') || session.step === 1) {
        if (session.mode === 1) {
            // no hay metodos disponibles
            if (session.methods.length === 0)
                return (
                    <NotificationBody>
                        <ErrorMessage
                            message={get_message('NO_PAYMENT_METHODS_AVAILABLE', lang)}
                        />
                    </NotificationBody>
                );

            // mostrar metodos disponibles
            return (
                <div className="card-body">
                    <h6>{get_message('CHOOSE_PAYMENT_METHOD', lang)}</h6>

                    <div className="d-grid gap-2 mt-4 px-md-4">
                        {session.methods.map((method, idx) => (
                            <Button
                                active={session.payment.pg_method === method.method}
                                key={idx}
                                variant="outline-secondary"
                                onClick={() => handle_pick_method(method.method)}
                                size="lg"
                                className="btn-method my-1 mx-2"
                            >
                                <img
                                    src={`https://cdn.paygol.com/images/methods/method_button_${method.method}.png?v=7`}
                                    alt={method.method}
                                    style={{
                                        maxWidth: '100%',
                                        height: '45px'
                                    }}
                                />
                            </Button>
                        ))}
                    </div>
                </div>
            );
        }

        const filtered_types = [];
        // solo mostrar los tipos de pago, que se encuentran presentes
        // en la lista de tipos de cada metodo disponible (asociado al request payment)        
        for (let i = 0; i < session.types.length; ++i) {
            for (let j = 0; j < session.methods.length; ++j) {
                if (session.methods[j].types.includes(session.types[i])) {
                    filtered_types.push(session.types[i]);
                    break;
                }
            }
        }

        // no hay tipos de pago disponibles
        if (filtered_types.length === 0)
            return (
                <NotificationBody>
                    <ErrorMessage
                        message={get_message('NO_PAYMENT_METHODS_AVAILABLE', lang)}
                    />
                </NotificationBody>
            );

        // mostrar tipos de pago disponibles - sorteados
        // en caso de requerir poner unos sobre otros se puede asociar un prio/weight a cada
        // metodo y sortear por ese valor
        return (
            <div className="card-body">
                <h6>{get_message('CHOOSE_PAYMENT_TYPE', lang)}</h6>

                <div className="d-grid gap-2 d-grid gap-2 mt-4">
                    {filtered_types.sort((a, b) => a.localeCompare(b)).map((type, idx) => (
                        <Button key={idx} active={session.type === type} variant="outline-secondary"
                            onClick={() => handle_pick_type(type)} size="lg" className="btn-method">
                            {get_message(type.toUpperCase(), lang)}
                        </Button>
                    ))}
                </div>
            </div>
        );
    }

    if (session.mode === 1) {
        // el metodo ya fue seleccionado: mostrar custom args
        return (
            <div className="card-body">
                <div className="d-grid gap-2 pt-4 px-md-4 text-start">
                    <Form onSubmit={handleSubmit}>
                        {session.custom_args.map((carg, idx) => (
                            <Form.Group className="mb-1" key={idx} controlId={carg.name}>
                                <Form.Label>{get_message(carg.desc, lang)}</Form.Label>
                                <Form.Control
                                    required
                                    isInvalid={errors.hasOwnProperty(carg.name)}
                                    onChange={onArgChange}
                                    value={custom[carg.name]}
                                    type={carg.name === 'pg_email' ? 'email' : 'text'}
                                    placeholder={get_message((carg.placeholder ? carg.placeholder : carg.desc), lang)}
                                />
                                {errors.hasOwnProperty(carg.name) && (<Form.Text key={idx} className="text-danger" style={{
                                    marginLeft: '10px',
                                    fontSize: '11px'
                                }}>
                                    {errors[carg.name]}
                                </Form.Text>)}
                            </Form.Group>
                        ))}

                        <Row>
                            <Col>
                                <div className="d-grid gap-2 my-3">
                                    <Button disabled={isWorking} variant="primary" type="submit">
                                        {get_message('PAY', lang)}
                                    </Button>

                                    <Button
                                        disabled={isWorking}
                                        variant="link"
                                        type="button"
                                        className='btn-back'
                                        onClick={handleBack}
                                    >
                                        {get_message('CHOOSE_ANOTHER_METHOD', lang)}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }

    // listado de metodos de pago
    if (session.hasOwnProperty('step') && session.step === 2) {
        const filtered_by_type = session.methods.filter(m => m.types.includes(session.type));

        // no hay metodos disponibles
        if (filtered_by_type.length === 0)
            return (
                <div className="card-body">
                    <Alert variant='danger'>
                        {get_message('NO_PAYMENT_TYPES_AVAILABLE', lang)}
                    </Alert>
                </div>
            );

        // mostrar metodos disponibles
        return (
            <div className="card-body">
                <h6>{get_message('CHOOSE_PAYMENT_METHOD', lang)}</h6>

                <div className="d-grid gap-2">
                    {filtered_by_type.map((method, idx) => (
                        <Button
                            key={idx}
                            active={session.payment.pg_method === method.method}
                            variant="outline-secondary"
                            onClick={() => handle_pick_method(method.method)}
                            size="lg"
                            className="btn-method my-1 mx-2"
                        >
                            <img
                                src={`https://cdn.paygol.com/images/methods/method_button_${method.method}.png?v=8`}
                                alt={method.method}
                                style={{
                                    maxWidth: '100%',
                                    height: '45px'
                                }}
                            />
                        </Button>
                    ))}
                </div>
            </div>
        );
    }

    // mostrar custom args
    return (
        <div className="card-body">
            <div className="d-grid gap-2">
                <Form onSubmit={handleSubmit}>

                    {session.custom_args.map((carg, idx) => (
                        <Form.Group className="mb-1" key={idx} controlId={carg.name}>
                            <Form.Label>{get_message(carg.desc, lang)}</Form.Label>
                            <Form.Control required type="text" isInvalid={errors.hasOwnProperty(carg.name)} onChange={onArgChange} value={custom[carg.name]} placeholder={get_message(carg.desc, lang)} />

                            <Form.Text key={idx} className="text-danger">
                                {errors.hasOwnProperty(carg.name) ? errors[carg.name] : ''}
                            </Form.Text>
                        </Form.Group>
                    ))}

                    <div className="row">
                        <div className="col">
                            <Button disabled={isWorking} variant="primary" type="submit">
                                {get_message('CONTINUE', lang)}
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    );
}

export { PaymentWindowMethods };
