// react
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';

// bootstrap
import Spinner from 'react-bootstrap/Spinner';

import Body from "../Body";
import { get_message } from "../../lang/messages";
import { Button, Col, Row } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { BiErrorAlt } from "react-icons/bi";
import { BsInfoSquare } from "react-icons/bs";
import { CheckoutPaymentStatus } from "../paymentwindow/services/api";
import { useCookies } from "react-cookie";
import DetailsItem from "./DetailsItem";
import Price from "../common/Price";
import Datetime from "../common/Datetime";

/**
 * Agregar validación del estado de la transacción
 */

// "main" base component
const CheckoutWindow = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [lang, setLang] = useState('ES');
    const [cancel_url, setCancelUrl] = useState('');
    const [success_url, setSuccessUrl] = useState('');
    const [query] = useState(new URLSearchParams(useLocation().search))

    const [transaction, setTransaction] = useState()

    const [cookies] = useCookies(['pgcheckouttoken'])

    const redirect = (url) => {
        window.location.href = url
    }

    const getTransactionDetails = (uuid) => {
        CheckoutPaymentStatus(uuid, cookies.pgcheckouttoken)
            .then(response => {
                setTransaction(response)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        const uuid = query.get('uuid')

        let interval = null

        const checkStatus = (uuid) => {
            CheckoutPaymentStatus(uuid, cookies.pgcheckouttoken)
                .then(response => {
                    if (response.status === 2) {
                        setError('')
                        setWaiting(false)
                        clearInterval(interval)
                    }

                    else if (response.status !== 1) {
                        setWaiting(false)
                        setError('No hemos podido confirmar el pago')
                        clearInterval(interval)
                    }
                })
                .catch(error => console.log(error))
        }

        setLang(query.get('lang') ? query.get('lang') : 'ES')

        if (null !== query.get('waiting')) {
            interval = setInterval(() => {
                checkStatus(uuid)
            }, 2000)

            setWaiting(true)
        } else {
            getTransactionDetails(uuid)
        }

        if (null === uuid) {
            setError('Missing Transaction');
            setIsLoaded(true);
            return;
        }

        // XXX: sanitize
        if (null !== query.get('error'))
            setError(query.get('error'));
        //setError("Checkout error: " + query.get('error'));

        if (null !== query.get('cancel_url'))
            setCancelUrl(query.get('cancel_url'));

        if (null !== query.get('success_url'))
            setSuccessUrl(query.get('success_url'));

        setIsLoaded(true);

        if (interval) {
            return () => clearInterval(interval)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [query]);

    // cargando...
    if (!isLoaded)
        return (
            <Body>
                <div className="card-header py-2"></div>
                <div className="card-body">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Body>
        );

    if (waiting)
        return (
            <Body>
                <div className="card-header">&nbsp;</div>

                <div className="card-body">
                    <h1 style={{
                        color: '#2896F3',
                        fontSize: '44px'
                    }}><BsInfoSquare /></h1>

                    <h5 style={{
                        color: '#2896F3',
                        fontWeight: '600'
                    }} className="mt-4">{get_message('TRANSACTION_IS_WAITING', lang)}</h5>

                    <h6 style={{
                        fontWeight: 'lighter'
                    }} className="mt-3">{get_message('TRANSACTION_IS_WAITING_MESSAGE', lang)}</h6>
                </div>
            </Body>
        );

    if ('' !== error)
        return (
            <Body>
                <div className="card-header">&nbsp;</div>

                <div className="card-body">
                    <h1 style={{
                        color: '#F50D54',
                        fontSize: '44px'
                    }}><BiErrorAlt /></h1>

                    <h5 style={{
                        color: '#F50D54',
                        fontWeight: '600'
                    }} className="mt-4">{get_message('TRANSACTION_HAS_FAILED', lang)}....</h5>

                    <h6 style={{
                        fontWeight: 'lighter'
                    }} className="mt-3">Por favor, inténtalo nuevamente</h6>
                </div>

                {cancel_url && (<div className="card-footer">
                    <Row>
                        <Col>
                            <div className="d-grid gap-2 my-3">
                                <Button
                                    variant="primary"
                                    type="button"
                                    style={{
                                        fontSize: '13px'
                                    }}
                                    onClick={() => redirect(cancel_url)}
                                >
                                    {get_message('BACK_TO_SITE', lang)}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>)}
            </Body>
        );

    return (
        <Body>
            <div className="card-header">&nbsp;</div>

            <div className="card-body">
                <h1 style={{
                    color: '#42C444',
                    fontSize: '44px'
                }}><BsCheckCircle /></h1>

                <h4 style={{
                    fontWeight: 300
                }}>{get_message('TRANSACTION_COMPLETED_SUCCESSFULLY', lang)}</h4>
                <div style={{
                    display: 'block',
                    marginTop: '1.5rem',
                    fontSize: '13px'
                }}>{get_message('TRANSACTION_COMPLETED_LINE_1', lang)}</div>
                <div style={{
                    display: 'block',
                    marginTop: '.5rem',
                    fontSize: '13px',
                    padding: '0 .5rem'
                }}>{get_message('TRANSACTION_COMPLETED_LINE_2', lang)} <br /><strong>{transaction?.email}</strong>.</div>

                <div style={{
                    color: '#999',
                    display: 'block',
                    marginTop: '2rem',
                    padding: '0 .5rem',
                    textAlign: 'left'
                }}>
                    <div style={{
                        width: '100%',
                        textTransform: 'uppercase',
                        borderBottom: '1px solid #ccc',
                        textAlign: 'center'
                    }}>Detalles del pago</div>

                    <DetailsItem
                        title='ID de transacción'
                        value={transaction?.transaction_id}
                    />

                    <DetailsItem
                        title='Fecha del pago'
                        value={<Datetime date={transaction?.paid_at} />}
                    />

                    <DetailsItem
                        title='Monto'
                        value={<Price amount={transaction?.price} currency={transaction?.currency} />}
                    />

                    <DetailsItem
                        title='Método de pago'
                        value={transaction?.payment_method}
                    />

                    <DetailsItem
                        title='Correo electrónico'
                        value={transaction?.email}
                    />
                </div>
            </div>

            {success_url && (<div className="card-footer">
                <Row>
                    <Col>
                        <div className="d-grid gap-2 my-3">
                            <Button
                                variant="primary"
                                type="button"
                                style={{
                                    fontSize: '13px'
                                }}
                                onClick={() => redirect(success_url)}
                            >
                                {get_message('BACK_TO_SITE', lang)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>)}
        </Body>
    );



}

export { CheckoutWindow };
