import { useContext } from 'react';
import { PaymentContext } from "./context";
import Spinner from 'react-bootstrap/Spinner';

import { get_message } from '../../lang/messages';
import Price from '../common/Price';
import { Col, Row } from 'react-bootstrap';

const PaymentWindowHeader = () => {
    const session = useContext(PaymentContext).msession.session;
    const { isLoaded } = useContext(PaymentContext).mloaded;
    const { lang } = useContext(PaymentContext).mlang;
    const { formattedprice } = useContext(PaymentContext).mformattedprice;

    // el componente "padre" no termina de cargar
    if (!isLoaded || session === undefined)
        return (
            <div className="card-header">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{get_message('LOADING', lang)}...</span>
                </Spinner>
            </div>
        );

    // componente "padre" cargado, pero con error (el setError() lo llama el "padre")
    if (session === null)
        return (
            <div className="card-header">
            </div>
        );

    // csrf token vencido
    if (session.code === 53)
        return (
            <div className="card-header">
            </div>
        );

    // cargado OK
    return (
        <>
            <div className="card-header">
                {/* <img src={session.customizations.logo !== null && session.customizations.logo !== '' ? session.customizations.logo : 'https://cdn.paygol.com/logo-paygol.svg'} alt="" className="card-img-merchant" /> */}
                <Row className="pb-4">
                    <Col xs={12} md={6} className="text-sm-center mt-4">
                        <span
                            className="d-inline-block text-truncate"
                            style={{ width: '100%' }}
                            title={session.fantasy_name ? session.fantasy_name : (session.payment.pg_name !== '' ? session.payment.pg_name : 'Webcheckout')}
                        >
                            {session.fantasy_name ? session.fantasy_name : (session.payment.pg_name !== '' ? session.payment.pg_name : 'Webcheckout')}
                        </span>
                    </Col>
                    <Col xs={12} md={6} className="text-sm-center text-md-end mt-4">
                        <Price
                            amount={formattedprice ? formattedprice : session.payment.pg_price}
                            currency={session.payment.pg_currency}
                        />
                    </Col>
                </Row>
            </div>

            {session.payment.pg_method && (<div className='card-body pb-0'>
                <img
                    src={`https://cdn.paygol.com/images/methods/method_button_${session.payment.pg_method}.png?v=7`}
                    alt={session.payment.pg_method}
                    style={{
                        maxWidth: '100%'
                    }}
                />

                {session.payment.pg_method === 'pagoefectivo' && (<>
                    <p
                        className="m-md-3"
                        style={{
                            textAlign: 'left',
                            fontSize: '12px'
                        }}><strong>Banca Móvil / Internet</strong> - Paga en BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa y Banco Pichincha, a través de la opción pago de servicios.</p>

                    <p
                        className="m-md-3"
                        style={{
                            textAlign: 'left',
                            fontSize: '12px'
                        }}><strong>Agentes y Bodegas</strong> - Deposita en BBVA, BCP, Interbank, Scotiabank, BanBif, Western Union, Tambo+, Kasnet, Ya Ganaste, Red Digital, Agente Niubiz, MoneyGram, Caja Arequipa, Disashop, Cellpower.</p>

                    <img
                        src={`https://cdn.paygol.com/images/methods/method_button_pagoefectivo_marcas.png`}
                        alt={session.payment.pg_method}
                        style={{
                            maxWidth: '100%'
                        }}
                    />
                </>)
                }
            </div>)}
        </>
    );
}

export { PaymentWindowHeader };
