// react
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';

// bootstrap
import Spinner from 'react-bootstrap/Spinner';

// "sub" components
import { PaymentWindowNotifications } from './notifications';
import { PaymentWindowHeader } from './header';
import { PaymentWindowMethods } from './methods';
import { PaymentWindowFooter } from './footer';

// shared context provider
import { PaymentContext } from "./context";

import { get_error_message } from '../../lang/index';

// api connector
import { LoadSession } from './services/api';
// local storage
import { getStorageValue, setStorageValue } from "./services/localstorage";
// cookies
import { useCookies } from 'react-cookie';

import Body from "../Body";


// "main" base component
const PaymentWindow = () => {
    // global context
    const [session, setSession] = useState(undefined);
    const [method, setMethod] = useState(null);
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [errors, setErrors] = useState([]);
    const [lang, setLang] = useState('ES');
    const [custom, setCustom] = useState(null);
    const [formatted_price, setFormattedPrice] = useState('');


    const [cookies] = useCookies(['pgcheckouttoken']);

    // local 
    const [query] = useState(new URLSearchParams(useLocation().search));

    // se pasan al context provider para compartir
    const msession = useMemo(() => ({ session, setSession }), [session]);
    const mmethod = useMemo(() => ({ method, setMethod }), [method]);
    const merror = useMemo(() => ({ error, setError }), [error]);
    const mloaded = useMemo(() => ({ isLoaded, setIsLoaded }), [isLoaded]);
    const mworking = useMemo(() => ({ isWorking, setIsWorking }), [isWorking]);
    const merrors = useMemo(() => ({ errors, setErrors }), [errors]);
    const mlang = useMemo(() => ({ lang, setLang }), [lang]);
    const mcustom = useMemo(() => ({ custom, setCustom }), [custom]);
    const mformattedprice = useMemo(() => ({ formatted_price, setFormattedPrice }), [formatted_price]);


    useEffect(() => {
        const sid = query.get('sid');

        if (null === sid) {
            setError('Missing Webcheckout session ID');
            setSession(null);
            setIsLoaded(true);
            return;
        }

        // setStorageValue(sid, dummy_data)

        let result = getStorageValue(sid, null);
        result = null;

        // load from cache
        if (null !== result) {
            if (
                result.payment.hasOwnProperty('pg_language') &&
                result.payment.pg_language !== "" &&
                result.payment.pg_language !== null &&
                ['PT', 'EN', 'ES'].indexOf(result.payment.pg_language)
            ) {
                setLang(result.payment.pg_language);
            }
            else {
                setLang("ES")
            }

            const code = query.get('code');

            if (null === code)
                setError('');
            else
                setError(get_error_message(code, lang));

            result.type = '';
            result.step = 1;

            if (query.get('mode') !== null) {
                if (query.get('mode') === '2')
                    result.mode = 2;
                else
                    result.mode = 1;
            }

            if (!('types' in result) || result.types.length === 0)
                result.mode = 1;

            result.mode = 1;

            setFormattedPrice(result.payment.pg_price)

            setSession(result);
            setIsLoaded(true)
            return;
        }

        // load from api
        LoadSession(sid, cookies.pgcheckouttoken)
            .then((result) => {
                // handle payments custom params errors
                if (result.hasOwnProperty('errors')) {
                    var errobj = {};
                    for (var i = 0; i < result.errors.length; ++i) {
                        errobj[result.errors[i]['loc'][0]] = result.errors[i]['msg'];
                    }

                    setErrors(errobj);
                }

                // handle payments errors
                if (!result.hasOwnProperty('payment') || result.payment === null) {
                    const code = result.hasOwnProperty('code') ? result.code : null;
                    const error_message = result.hasOwnProperty('error_message') && result.error_message !== "" ? result.error_message : null;

                    if (null === code) {
                        setError('Session error');
                    }
                    else {
                        if (error_message === null)
                            setError(get_error_message(code, lang));
                        else
                            setError(get_error_message(code, lang) + ' : ' + error_message);
                    }

                    setSession(null);
                    setIsLoaded(true);
                    return;
                }

                if (!result.hasOwnProperty('mode'))
                    result.mode = 1; // 1 = ventana de pagos sin tipos, 2 = ventana de pagos con tipos

                if (query.get('mode') !== null) {
                    if (query.get('mode') === '2')
                        result.mode = 2;
                    else
                        result.mode = 1;
                }

                result.mode = 1;

                /*
                    if(!('types' in result) || null === result.types || result.types.length === 0)
                    result.mode = 1;
        
        
                if('types' in result && null === result.types)
                result.types = ["all"];
                */

                if (
                    result.payment.hasOwnProperty('pg_language') &&
                    result.payment.pg_language !== "" &&
                    result.payment.pg_language !== null &&
                    ['PT', 'EN', 'ES'].indexOf(result.payment.pg_language)
                ) {
                    setLang(result.payment.pg_language);
                }
                else {
                    setLang("ES")
                }

                const include_types = result.mode === 2;

                if (result.payment.pg_method !== "" && result.payment.pg_method !== null) {

                    for (let i = 0; i < result.methods.length; ++i) {

                        // se asume el primer type
                        if (result.methods[i].types === null)
                            result.methods[i].types = [];

                        // cargar custom args
                        if (result.methods[i].custom_args === null)
                            result.methods[i].custom_args = [];

                        if (result.methods[i].method === result.payment.pg_method) {

                            if (include_types)
                                result.step = 3;
                            else
                                result.step = 2;

                            if (result.methods[i].types !== null && result.methods[i].types.length !== 0)
                                result.type = result.methods[i].types[0];
                            else
                                result.type = "tipo";

                            // cargar custom args
                            if (result.methods[i].custom_args !== null && result.methods[i].custom_args.length !== 0)
                                result.custom_args = result.methods[i].custom_args;
                            else
                                result.custom_args = [];

                            // el pg_method que viene existe en el listado, pasar a sgte. etapa
                            let temp2 = {};
                            for (let i = 0; i < result.custom_args.length; ++i) {
                                // setear '' si no viene 
                                if (!result.payment.hasOwnProperty(result.custom_args[i].name)
                                    || null === result.payment[result.custom_args[i].name]
                                ) {
                                    //temp.payment[temp.custom_args[i].name] = '';
                                    if (custom !== null && custom.hasOwnProperty(result.custom_args[i].name))
                                        temp2[result.custom_args[i].name] = custom[result.custom_args[i].name];
                                    else
                                        temp2[result.custom_args[i].name] = '';
                                }
                                else {
                                    temp2[result.custom_args[i].name] = result.payment[result.custom_args[i].name];
                                }
                            }

                            // set states
                            setCustom(temp2);
                            setMethod(result.methods[i]);
                            setSession(result);
                            setStorageValue(sid, result);

                            setFormattedPrice(result.payment.pg_price);
                            /*
                             new Intl.NumberFormat(
                                 'es-CL',
                                 { style: 'currency', currency: result.payment.pg_currency }
                             ).format(result.payment.pg_price)
                         )*/


                            const code = result.hasOwnProperty('ecode') ? result.ecode : null;
                            const error_message = result.hasOwnProperty('error_message') && result.error_message !== "" ? result.error_message : null;

                            if (null === code) {
                                setError('');
                            }
                            else {
                                if (error_message === null)
                                    setError(get_error_message(code, lang));
                                else
                                    setError(get_error_message(code, lang) + ' : ' + error_message);
                            }

                            setIsLoaded(true);
                            return;
                        }
                    }

                }
                else {
                    for (let i = 0; i < result.methods.length; ++i) {
                        if (result.methods[i].types === null)
                            result.methods[i].types = [];

                        if (result.methods[i].custom_args === null)
                            result.methods[i].custom_args = [];
                    }
                }

                if (!result.hasOwnProperty('type'))
                    result.type = '';

                result.step = 1;

                setSession(result);
                setStorageValue(sid, result);

                const code = query.get('code');

                if (null === code)
                    setError('');
                else
                    setError(get_error_message(code));

                setIsLoaded(true);
            },
                (error) => {
                    setError(error);
                    setSession(null);
                    setIsLoaded(true);
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [query]);//, cookies, lang]);

    // cargando...
    if (!isLoaded)
        return (
            <Body>
                <div className="card-body">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Body>
        );

    // invalid session (ej: expired sid, missing sid, no local storage, etc)
    if (null === session)
        return (
            <Body>
                <div className="card-body">
                    <PaymentContext.Provider value={{ merror, msession, mlang }}>
                        <PaymentWindowNotifications />
                    </PaymentContext.Provider>
                </div>
            </Body>
        );

    // webcheckout session ok
    // cargar context provider y el resto de los "sub" componentes

    return (
        <PaymentContext.Provider value={{ msession, mmethod, merror, mloaded, mworking, merrors, mlang, mcustom, mformattedprice }}>
            <Body>
                <PaymentWindowHeader />

                <PaymentWindowNotifications />

                <PaymentWindowMethods />
                <PaymentWindowFooter />
            </Body>
        </PaymentContext.Provider>
    );
}

export { PaymentWindow };
