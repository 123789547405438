import React from 'react'

export default function SandboxBadge() {
    return (
        <div style={{
            background: '#FC0755',
            color: 'white',
            display: 'block',
            height: '10em',
            left: '-5.3125em',
            paddingTop: '7em',
            position: 'fixed',
            textAlign: 'center',
            top: '-4.375em',
            WebkitTransform: 'rotate(-45deg)',
            transform: 'rotate(-45deg)',
            width: '11.875em',
            zIndex: '999',
            fontWeight: 800
        }}>Sandbox</div>
    )
}
