import { Stack } from "react-bootstrap";

export default function PoweredBy({
    variant = 'white'
}) {
    const logo = variant === 'default' ? 'powered-by-xs.png' : 'powered-by-white-xs.png'

    return (
        <Stack gap={1} className="col-md-5 mx-auto text-center m-4">
            <a href="https://paygol.com" target="_blank" rel="noopener noreferrer">
                <img src={`https://cdn.paygol.com/images/v2/${logo}`} alt="Paygol" />
            </a>
        </Stack>
    )
}
