import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
    const { t } = useTranslation()

    return (
        <Container className='mt-4'>
            <Row className="justify-content-center">
                <Col xs={11} md={10} lg={7} style={{
                    height: '40px'
                }} className='pg-gradient90' />
            </Row>

            <Row className="justify-content-center">
                <Col xs={11} md={10} lg={7} className="my-4 p-2">
                    <img src="https://cdn.paygol.com/images/v2/paygol-161x45.png" alt='Paygol' />
                </Col>

                <Col xs={11} md={10} lg={7} className="my-5 p-2 text-center">
                    <h1 style={{
                        color: '#08097D',
                        fontSize: '5rem',
                        fontWeight: 800
                    }}>404</h1>

                    <h1 style={{
                        color: '#08097D'
                    }}>{t('Page Not Found')}</h1>
                </Col>
            </Row>
        </Container>
    )
}
